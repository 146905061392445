import './index.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import App from './App'
import { CheckoutProvider } from './contexts/checkoutContext'
import { DataProvider } from './contexts/dataContext'
import { ThemeProvider } from './contexts/themeContext'
import * as serviceWorker from './serviceWorker'
import config from './utils/config'

const stripePromise = loadStripe(config.stripeKey)

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <ThemeProvider>
        <BrowserRouter>
          <DataProvider>
            <CheckoutProvider>
              <App />
            </CheckoutProvider>
          </DataProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Elements>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
