import * as querystring from 'querystring'

import config from '../utils/config'

interface APIRequest {
  url?: string
  method?: "GET" | "POST" | "PUT" | "DELETE" | "OPTIONS"
  baseURL?: string
  body?: any
  contentType?: "application/x-www-form-urlencoded" | "application/json"
  authorization?: string | null
  onAuthorization?: Function
  autoAuth?: boolean
  binaryData?: boolean
}

const requestTypes: { [id: string]: APIRequest } = {
  default: {
    url: "",
    method: "POST",
    baseURL: config.serverConfig.serverUrl,
    contentType: "application/x-www-form-urlencoded",
    autoAuth: true,
    authorization: null,
  },
}

export function setDefaultAPIRequest(apiRequest: APIRequest, profile?: string, replace?: boolean) {
  requestTypes[profile || "default"] = replace
    ? apiRequest
    : { ...requestTypes[profile || "default"], ...apiRequest }
}

// fetch timeout
function fetchWithTimeout(ms: number, promise: Promise<any>) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error("Request timed out."))
    }, ms)
    promise.then(resolve, reject)
  })
}

// fetch error catcher
function onFetchError(error: any) {
  throw error
}

export async function request(apiRequest: APIRequest, profile?: string): Promise<any> {
  // prepare request
  const req: APIRequest = { ...requestTypes[profile || "default"], ...apiRequest }
  const headers: any = {}

  // set authorization header if defined
  if (req.authorization != undefined) {
    headers.Authorization = `${req.authorization}`
  }

  // set content type if defined
  if (req.contentType != undefined) {
    headers["Content-Type"] = `${req.contentType}`
  }

  // prepare url and options
  const url = `${req.baseURL}${req.url}`
  const body =
    req.contentType === "application/x-www-form-urlencoded"
      ? querystring.stringify(req.body)
      : req.body
  const options: RequestInit = { method: req.method, body, headers }

  console.log("Request >", url, options)

  // get response with timeout and error catch
  const response: any = await fetchWithTimeout(
    config.serverConfig.timeout,
    fetch(url, options).catch(onFetchError)
  )

  if (req.binaryData === true) {
    return response
  }

  if (
    response.status === 401 &&
    req.autoAuth === true &&
    typeof req.onAuthorization === "function"
  ) {
    const { authorization } = await req.onAuthorization()
    return request({ ...apiRequest, authorization, autoAuth: false }, profile)
  }

  const data = await response.json()
  console.log("Response >", response, data)
  if (response.status === 200) {
    return data
  }
  throw data
}
