import { title } from 'process'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Textfit } from 'react-textfit'

import Footer from '../../components/Footer/Footer'
import Loading from '../../components/Loading/Loading'
import PricingItem from '../../components/PricingItem/PricingItem'
import RetryLoading from '../../components/RetryLoading/RetryLoading'
import useCheckout from '../../contexts/checkoutContext'
import useData from '../../contexts/dataContext'
import Product from '../../models/Product'

import { igID } from '../../utils/config'

const LandingPage = () => {
  const history = useHistory()


  const { actions, state } = useData()

  const loadData = () => {
    actions.loadLanding(igID)
    actions.loadProducts(igID)
  }

  useEffect(() => {
    loadData()
  }, [])

  if (state.landingError != undefined || state.productsError != undefined) {
    return (
      <RetryLoading message={(state.landingError || state.productsError)!} onRetry={loadData} />
    )
  }

  if (state.landing == undefined || state.products == undefined) {
    return <Loading />
  }

  const {
    fullName,
    avatarURL,
    verified,
    description,
    beneficiaryName,
    beneficiaryURL,
  } = state.landing

  const renderBeneficiary = () => {
    if (!beneficiaryName) {
      return undefined
    }
    return (
      <>
        <div className="supportTextContainer">
          <span className="supportText">IN SUPPORT OF </span>
          <span className="supportLink">{beneficiaryName}</span>
        </div>
        {beneficiaryURL && <img src={beneficiaryURL} alt="banner" className="supportBanner" />}
      </>
    )
  }
  const products = state.products

  const clickedItem = (product: Product) => {
    history.push(`/checkout`, { selectedProduct: product })
  }

  return (
    <>
      <Helmet title={fullName}>
        <meta
          name="description"
          content={`Get access to ${igID}'s exclusive content, latest stories, and more.`}
        />
      </Helmet>
      <div className="titleContainer">
        {
          verified && (
            <span className="checkmark" style={{ visibility: "hidden" }} />
          ) /* Balance out right-hand checkmark */
        }
        <div className="title">
          <Textfit className="titleDynamic" max={38} mode="single">
            {fullName}
          </Textfit>
        </div>
        {verified && <img src="/img/bluecheck.png" alt="blue checkmark" className="checkmark" />}
      </div>
      <span className="subtitle u-margin-bottom">ALL ACCESS</span>
      <img src={avatarURL} alt="avatar" className="avatar u-margin-bottom" />
      <p className="content u-margin-bottom">{description}</p>
      {renderBeneficiary()}
      <div className="pricingContainer">
        {products.map((product, index) => (
          <PricingItem product={product} onClick={clickedItem} key={index} />
        ))}
      </div>
      {/*
      <div className="howItWorksHeader">
        <span className="text">HOW IT WORKS</span>
        <img src="/img/info.png" alt="info" className="info" />
      </div>
      <ul className="benefitList">
        <li className="benefit">
          ALL ACCESS members are added to Close Friend's List on the Artist's Instagram.
        </li>
        <li className="benefit">
          100% of funds from subscriptions go directly to the artist and the cause they support,
          minus processing fees.
        </li>
        <li className="benefit">Membership becomes active within 24-hours of confirmed payment.</li>
      </ul>
      */}
      <Footer />
    </>
  )
}

export default LandingPage
