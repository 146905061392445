import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'

import LandingConfiguration, { ColorTheme } from '../models/LandingConfiguration'
import Product from '../models/Product'
import { getLandingConfiguration, getStripePricing } from '../services/scottie-service'
import config from '../utils/config'
import useTheme from './themeContext'

type State = {
  landing?: LandingConfiguration
  products?: Product[]
  selectedProduct?: Product
  landingError?: string
  productsError?: string
}

interface Actions {
  loadLanding(igID: string): Promise<void>
  loadProducts(igID: string): Promise<void>
}

const Context = React.createContext<{ state: State; actions: Actions }>(null as any)

const DataProvider = ({ children }: any) => {
  const [products, setProducts] = useState<Product[] | undefined>(undefined)
  const [landing, setLanding] = useState<LandingConfiguration | undefined>(undefined)
  const selectedProductJSON = (useLocation().state as any)?.selectedProduct

  let selectedProduct: Product | undefined = undefined

  if (selectedProductJSON != undefined) {
    selectedProduct = new Product("", "", 0, "")
    Object.assign(selectedProduct, selectedProductJSON)
  }

  const [landingError, setLandingError] = useState<string | undefined>(undefined)
  const [productsError, setProductsError] = useState<string | undefined>(undefined)

  const forceTheme = useTheme().actions.forceTheme

  const loadLanding = async (igID: string) => {
    if (landing != undefined) {
      return
    }

    setLandingError(undefined)

    try {
      const configuration: LandingConfiguration = await getLandingConfiguration(igID)
      if (configuration.colorTheme == undefined) {
        configuration.colorTheme = ColorTheme.system
      } else {
        const stringTheme: string = configuration.colorTheme.toLowerCase()
        const theme = ColorTheme[stringTheme as keyof typeof ColorTheme] || ColorTheme.system
        configuration.colorTheme = theme
      }

      forceTheme(configuration.colorTheme)

      setLanding(configuration)
    } catch (error) {
      console.log(error)
      setLandingError(error.exception || config.unexpectedErrorMessage)
    }
  }

  const loadProducts = async (igID: string) => {
    try {
      setProductsError(undefined)
      const JSON = await getStripePricing(igID)

      let result: Product[] = []

      for (const key of Object.keys(JSON)) {
        const productJSON = JSON[key]

        const price = productJSON.prices.month["1"].price
        const id = productJSON.prices.month["1"].id

        result.push(
          new Product(productJSON.name, productJSON.description, price, id, productJSON.limit)
        )
      }

      // Uncomment this to use mocked product data for testing
      // const mockedProducts = [
      //   new Product(
      //     "ALL ACCESS PLUS",
      //     "Close Friends List + Priority Inbox Thank You Shoutout",
      //     2499,
      //     "1",
      //     100
      //   ),
      //   new Product("ALL ACCESS", "Close Friends List + Priority Inbox", 1499, "2"),
      //   new Product("ALL ACCESS", "Close Friends List", 499, "3"),
      // ]

      // setProducts(mockedProducts)

      setProducts(result)
    } catch (error) {
      console.log(error)
      setProductsError(error.exception || config.unexpectedErrorMessage)
    }
  }

  const value = {
    actions: { loadLanding, loadProducts },
    state: {
      landing,
      products,
      selectedProduct,
      landingError,
      productsError,
    },
  }

  return <Context.Provider value={value}> {children} </Context.Provider>
}

export { DataProvider }

const useData = () => useContext(Context)

export default useData
