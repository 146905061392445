import './PrimaryButtonStyles.scss'

import React from 'react'

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  showArrow: boolean
  title: string
  titleClass?: string
  containerClass?: string
}

const PrimaryButton = ({ onClick, title, titleClass, containerClass, showArrow }: Props) => {
  let finalContainerClass = "primaryButton u-margin-bottom"

  if (containerClass != undefined) {
    finalContainerClass += " " + containerClass
  }

  return (
    <button
      className={finalContainerClass}
      onClick={(event) => {
        onClick(event)
      }}
    >
      {showArrow && <span className="spacer"></span>}
      <span className={titleClass ? titleClass : "text"}>{title}</span>
      {showArrow && <img src="/img/arrow.png" alt="arrow" className="arrow" />}
    </button>
  )
}

export default PrimaryButton
