const subs = window.location.hostname.split(".")

// Rule: production by default, *.devo.* is devo website
export const isProduction = window.location.hostname.indexOf("devo") === -1

// Creator igID is captured as 'eric' in 'eric.halo.link'
export const igID = subs.length >= 3 && ["www", "devo"].indexOf(subs[0]) === -1 ? subs[0] : ''

const config = {
  serverConfig: {
    serverUrl: isProduction ? 'https://api.artistpass.us' : 'https://api.devo.artistpass.us',
    timeout: 30000,
  },
  stripeKey: isProduction ? "pk_live_51H5RTfA4nGQJVXNlidctfDpY74NSksC49ovZy1t99LYYG59mtl1LqvaqaJZZKSo5jNfpMue3ELhMi5Eisp5vFQfr00wED5YHBI" : "pk_test_51H5RTfA4nGQJVXNl6dMCPM6Uveg0Fsqiui1l8phZ3Inji8TZVhZSQfuiczX5tCa99jGQtIsQRQOecYTQMt4olQbo00F0p4N5Hv",
  instagram: {
    appID: "266446581100897",
    redirectURI: "https://www.artistpass.us/fb/authorized",
    scope: "user_profile",
    responseType: "code",
  },
  unexpectedErrorMessage:
    "An unexpected error has occured. If this continues happening, contact support@artistpass.us.",
}

export default config
