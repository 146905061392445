import './LoadingStyles.scss'

import React from 'react'

const Loading = () => {
  return (
    <div className="lds-ripple">
      <div className="lds-center">
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Loading
