import './RetryLoadingStyles.scss'

import React from 'react'

import PrimaryButton from '../PrimaryButton/PrimaryButton'

interface Props {
  message: String
  onRetry: () => void
}

const RetryLoading = ({ message, onRetry }: Props) => {
  return (
    <div className="container">
      <span className="error u-margin-bottom-medium">{message}</span>
      <PrimaryButton showArrow={false} title="RETRY" onClick={onRetry} />
    </div>
  )
}

export default RetryLoading
