import { request } from './rest-service'

export enum ClientChannel {
  EMAIL = 2,
  INSTAGRAM_LEGACY_ID = 37,
  INSTAGRAM_HANDLE = 38,
  INSTAGRAM_GRAPH_ID = 39,
}

interface InstagramAccessPacket {
  username: string
  graphId: string // Graph API id
  legacyId: string // Legacy id
}

export async function getLandingConfiguration(instagram_handle: string) {
  return request({
    url: "/v1/creator/get_landing_configuration",
    method: "POST",
    body: {
      instagram_handle,
    },
  })
}

export async function getStripePricing(instagram_handle: string) {
  return request({
    url: "/v1/purchases/stripe/get_pricing",
    method: "POST",
    body: {
      instagram_handle,
    },
  })
}

export async function createSubscription(
  paymentMethodId: string,
  priceId: string,
  email: string,
  instagramHandle: string
) {
  const channel: any = {}

  channel[`${ClientChannel.INSTAGRAM_HANDLE}`] = instagramHandle

  return request({
    url: "/v1/purchases/stripe/create_subscription",
    method: "POST",
    body: {
      payment_method_id: paymentMethodId,
      price_id: priceId,
      email: email,
      customer_info: JSON.stringify(channel),
    },
  })
}

export async function getStripeSession(
  price_id: string,
  customer_info: { [channel_id in ClientChannel]: string },
  success_url: string,
  cancel_url: string
): Promise<string> {
  return request({
    url: "/v1/purchases/stripe/create_session",
    method: "POST",
    body: {
      price_id,
      customer_info: JSON.stringify(customer_info),
      success_url,
      cancel_url,
    },
  })
}

export async function getInstagramAccess(code: string): Promise<InstagramAccessPacket> {
  const data = await request({
    url: "/v1/instagram/access",
    method: "POST",
    body: {
      code,
    },
  })

  if (typeof data !== "string") {
    throw new Error("Invalid instagram response data format")
  }

  const json = JSON.parse(data)

  if (json.username == undefined || json.ig_id == undefined || json.id == undefined) {
    throw new Error("Invalid instagram response data")
  }

  return {
    username: json.username,
    legacyId: json.ig_id,
    graphId: json.id,
  }
}
