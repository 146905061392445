import './CardSection.scss'

import React from 'react'

import { CardElement } from '@stripe/react-stripe-js'
import { StripeCardElementOptions } from '@stripe/stripe-js'

function CardSection() {
  const computedStyle = getComputedStyle(document.documentElement)
  const textColor = computedStyle.getPropertyValue("--text-primary")
  const invalidColor = computedStyle.getPropertyValue("--invalid-text")

  const cardElementOptions: StripeCardElementOptions = {
    style: {
      base: {
        color: textColor,
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: textColor + "66", // 66 -> 0.4 opacity
        },
        fontWeight: "700",
        fontFamily: '"Avenir"',
      },
      invalid: {
        color: invalidColor,
        iconColor: invalidColor,
      },
    },
  }

  return (
    <label className="stripe-card-details-container">
      <span className="card-details-title">Card details</span>
      <CardElement options={cardElementOptions} />
    </label>
  )
}

export default CardSection
