import React from 'react'

import styles from './Footer.module.scss'

const Footer = () => {
  const year = new Date().getFullYear()

  return (
    <div className={styles.container}>
      <span className={styles.text}>© {year} Hibiscus Lab Inc</span>
      <div>
        <a className={styles.text} href="/terms_customer.html">
          Terms
        </a>
        <a className={styles.text} href="/privacy_customer.html">
          Privacy Policy
        </a>
        <a className={styles.text} href="/cookie.html">
          Cookie Policy
        </a>
      </div>
    </div>
  )
}

export default Footer
