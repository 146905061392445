import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Footer from '../../components/Footer/Footer'
import CreatorHeader from '../../components/Header/CreatorHeader'
import Loading from '../../components/Loading/Loading'
import PricingItem from '../../components/PricingItem/PricingItem'
import RetryLoading from '../../components/RetryLoading/RetryLoading'
import useData from '../../contexts/dataContext'
import styles from './checkoutSuccessPage.module.scss'

import { igID } from '../../utils/config'

const CheckoutSuccessPage = () => {
  const { actions, state } = useData()
  const history = useHistory()

  const loadData = () => {
    actions.loadLanding(igID)
  }

  const product = state.selectedProduct

  useEffect(loadData, [])

  useEffect(() => {
    if (product == undefined) {
      history.replace("/badRequest.html")
    }
  }, [product])

  if (state.landingError != undefined) {
    return <RetryLoading message={state.landingError} onRetry={loadData} />
  }

  if (state.landing == undefined) {
    return <Loading />
  }

  if (product == undefined) {
    return null
  }

  /*    public title: string,
    public subtitle: string,
    public priceInCents: number,
    public id: string,
    public limit?: number
    */

  const productUpsell1 = {
    title: "BEER",
    subtitle: `Send @${igID} a virtual beer 🍺`,
    id: "123",
    priceInCents: 4999,
    formattedPrice: "$0.99",
    period: '🍺' 
  }
  const productUpsell2 = {
    title: "FOLLOW BACK",
    subtitle: `Become IG friends with @${igID}`,
    limit: 100,
    id: "123",
    priceInCents: 4999,
    formattedPrice: "$49.99",
  }

  return (
    <div className={styles.container}>
      <CreatorHeader />
      <span className={`${styles.text} ${styles.thankYou}`}>Success!
        Your @{igID} {product?.title.toUpperCase()} will be activated as soon as they approve your request.
      </span>
      <span className={styles.secondary}>
        
      </span>
      <div className="pricingContainer">
        <PricingItem onClick={() => {}} product={productUpsell1}/>
        <PricingItem onClick={() => {}} product={productUpsell2} />
      </div>
      <Footer />
    </div>
  )
}

export default CheckoutSuccessPage
