import React, { useState } from 'react'

import Footer from '../../components/Footer/Footer'
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton'
import styles from './homePage.module.scss'

const HomePage = () => {
  const [igId, setIgId] = useState("")
  const [isValid, setIsValid] = useState(false)

  const submitEmail = () => {
    if (!isValid || igId.length === 0) {
      return
    }

    const email = "info@artistpass.us"
    const subject = `@${igId} interest in Artist Pass`
    const body = `Hello, I'd like to learn more about Artist Pass. My Instagram account is @${igId}. Thanks!`

    window.open(`mailto:${email}?subject=${subject}&body=${body}`)
  }

  return (
    <div className={styles.container}>
      <span className={styles.title}>ARTIST PASS</span>
      <span className={`${styles.content} ${styles.spacer}`}>
        Earn $ or donate to a cause with subscribers to your exclusive content directly on
        Instagram.
      </span>
      <span className={styles.content}>Apply for an account now.</span>
      <a className={styles.content} href="mailto:info@artistpass.us">
        info@artistpass.us
      </a>
      <form className={styles.form} action="#">
        <input
          value={igId}
          onChange={(event) => {
            setIgId(event.target.value)
            setIsValid(event.target.validity.valid)
          }}
          className={styles.igId}
          type="text"
          placeholder="Instagram @username"
          id="igHandle"
          required
        />
        <div className={styles.buttonContainer}>
          <PrimaryButton
            title="SUBMIT"
            titleClass={styles.buttonText}
            containerClass={styles.button}
            showArrow
            onClick={submitEmail}
          />
        </div>
      </form>
      <Footer />
    </div>
  )
}

export default HomePage
