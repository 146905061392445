import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'

import { ColorTheme } from '../models/LandingConfiguration'

type State = { theme: ColorTheme }

interface Actions {
  forceTheme(theme: ColorTheme): void
}

const Context = React.createContext<{ state: State; actions: Actions }>(null as any)

function onThemeChange(callback: () => void) {
  return (event: any) => {
    if (!event || !event.matches) {
      return
    }

    callback()
  }
}

const colorSchemes = {
  light: "(prefers-color-scheme: light)",
  dark: "(prefers-color-scheme: dark)",
}

const ThemeProvider = ({ children }: any) => {
  const [theme, setTheme] = useState(ColorTheme.system)
  const [hasForcedTheme, setHasForcedTheme] = useState(false)

  const updateTheme = (theme: ColorTheme) => {
    setTheme(theme)
    document.documentElement.setAttribute("data-theme", theme)
  }

  const updateThemeIfNotForced = (theme: ColorTheme) => {
    if (hasForcedTheme) {
      return
    }
    updateTheme(theme)
  }

  const forceTheme = (theme: ColorTheme) => {
    if (theme === ColorTheme.system) {
      return
    }
    setHasForcedTheme(true)
    updateTheme(theme)
  }

  useEffect(() => {
    // SSR or matchMedia not supported
    if (typeof window === "undefined" || !window.matchMedia) {
      return
    }

    const lightMatch = window.matchMedia(colorSchemes.light)
    const onLightMatches = onThemeChange(() => updateThemeIfNotForced(ColorTheme.light))

    lightMatch.addListener(onLightMatches)

    const darkMatch = window.matchMedia(colorSchemes.dark)
    const onDarkMatches = onThemeChange(() => updateThemeIfNotForced(ColorTheme.dark))

    darkMatch.addListener(onDarkMatches)

    return () => {
      lightMatch.removeListener(onLightMatches)
      darkMatch.removeListener(onDarkMatches)
    }
  }, [hasForcedTheme])

  useLayoutEffect(() => {
    // SSR or matchMedia not supported
    if (typeof window === "undefined" || !window.matchMedia) {
      return
    }

    if (window.matchMedia(colorSchemes.dark).matches) {
      updateThemeIfNotForced(ColorTheme.dark)
    } else if (window.matchMedia(colorSchemes.light).matches) {
      updateThemeIfNotForced(ColorTheme.light)
    }
  }, [])

  const value = {
    actions: { forceTheme },
    state: { theme },
  }

  return <Context.Provider value={value}> {children} </Context.Provider>
}

export { ThemeProvider }

const useTheme = () => useContext(Context)

export default useTheme
