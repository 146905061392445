export default class Product {
  constructor(
    public title: string,
    public subtitle: string,
    public priceInCents: number,
    public id: string,
    public period?: string,
    public limit?: number,
  ) {}

  get formattedPrice() {
    return `$${(this.priceInCents / 100).toFixed(2)}`
  }
}
