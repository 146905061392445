import React from 'react'

import Product from '../../models/Product'

interface Props {
  product: Product
  onClick: (product: Product) => void
  className?: string
}

const PricingItem = ({ onClick, className, product}: Props) => {
  const { title, formattedPrice, limit, subtitle, period } = product
  let finalClassName = "pricingItem"
  if (className) {
    finalClassName += " " + className
  }

  return (
    <div className={finalClassName}>
      <span className="title">{title.toUpperCase()}</span>
      {limit && <span className="limited">Limited to {limit} Members</span>}
      {subtitle && <span className="subtitle">{subtitle}</span>}
      <button className="button u-margin-top" onClick={() => onClick(product)}>
        <span className="spacer"></span>
        <span>
          {formattedPrice}
          <span className="monthText">/{period || 'month'}</span>
        </span>
        <img src="/img/arrow.png" alt="arrow" className="arrow" />
      </button>
    </div>
  )
}

export default PricingItem
