import React, { useEffect, useRef, useState } from 'react'

import useData from '../../contexts/dataContext'

import './HeaderStyles.scss'


const CreatorHeader = () => {
  const configuration = useData().state.landing

  if (configuration == undefined) {
    return null
  }

  const { avatarURL, fullName, verified } = configuration

  return (
    <div className="header">
      <img src={avatarURL} alt="avatar" className="avatar" />
      <span className="title">{fullName}</span>
      {verified && (
        <img src="/img/bluecheck.png" alt="blue checkmark" className="checkmark" />
      )}
    </div>
  )
}

export default CreatorHeader