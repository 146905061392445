import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js'

import CardSection from '../../components/CardSection/CardSection'
import Footer from '../../components/Footer/Footer'
import Loading from '../../components/Loading/Loading'
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton'
import RetryLoading from '../../components/RetryLoading/RetryLoading'
import Separator from '../../components/Separator/Separator'
import useCheckout from '../../contexts/checkoutContext'
import useData from '../../contexts/dataContext'
import styles from './checkoutPage.module.scss'
import CreatorHeader from '../../components/Header/CreatorHeader'

import { igID } from '../../utils/config'


const CheckoutPage = () => {
  const emailInputRef = useRef<HTMLInputElement | null>(null)
  const usernameInputRef = useRef<HTMLInputElement | null>(null)

  const reportFormValidity = () => {
    return emailInputRef.current?.reportValidity() && usernameInputRef.current?.reportValidity()
  }

  const { actions, state } = useData()
  const {
    state: {
      paymentRequest,
      email,
      username,
      checkoutError,
      isProcessingCheckout,
      isLoadingPaymentMethods,
    },
    actions: { setEmail, setUsername, createPaymentRequest, checkoutWithCard },
  } = useCheckout()

  const product = state.selectedProduct

  const stripe = useStripe()
  const history = useHistory()

  const loadData = () => {
    actions.loadLanding(igID)
  }

  useEffect(loadData, [])

  useEffect(() => {
    if (product == undefined) {
      history.replace("/badRequest.html")
    }
  }, [product])

  useEffect(() => {
    if (product) {
      createPaymentRequest(product, igID)
    }
  }, [stripe, product])

  if (state.landingError != undefined) {
    return <RetryLoading message={state.landingError} onRetry={loadData} />
  }

  if (state.landing == undefined) {
    return <Loading />
  }

  if (product == undefined) {
    return null
  }

  const renderLoading = () => {
    if (!isProcessingCheckout) {
      return undefined
    }
    return (
      <div className={styles.loading}>
        <div className={styles.center}>
          <Loading />
        </div>
      </div>
    )
  }

  const renderPaymentMethods = () => {
    if (isLoadingPaymentMethods) {
      return (
        <div className={styles.paymentLoadingContainer}>
          <div className={styles.spinnerContainer}>
            <Loading />
          </div>
          <span className={styles.text}>Loading payment options</span>
        </div>
      )
    }
    return (
      <>
        {paymentRequest && (
          <div className="applePay">
            <PaymentRequestButtonElement
              options={{ paymentRequest }}
              onClick={(event) => {
                if (!reportFormValidity()) {
                  event.preventDefault()
                  return
                }
              }}
            />
          </div>
        )}
        {paymentRequest && <Separator text="OR" />}
        <CardSection />
        <PrimaryButton
          containerClass={styles.button}
          showArrow={false}
          title={`Pay ${product.formattedPrice}`}
          onClick={(event) => {
            event.preventDefault()
            if (!reportFormValidity()) {
              return
            }

            checkoutWithCard(product, igID)
          }}
        />
      </>
    )
  }

  return (
    <>
      <div className={styles.container}>
        {renderLoading()}
        <CreatorHeader />
        <span className={styles.content}>
          You're just one step away from @{igID}'s ALL ACCESS for only {product.formattedPrice}
          /month.
        </span>
        <form className={styles.form} action="#">
          <span className={styles.sectionTitle}>Your Account</span>
          <input
            ref={emailInputRef}
            value={email}
            onChange={(event) => {
              setEmail(event.target.value.trim())
            }}
            className={styles.input}
            type="email"
            placeholder="Email"
            id="email"
            required
          />
          <input
            ref={usernameInputRef}
            value={username}
            onChange={(event) => {
              setUsername(event.target.value.trim())
            }}
            className={styles.input}
            type="text"
            placeholder="Instagram username"
            id="igHandle"
            required
          />
          {checkoutError && (
            <>
              <span className={`${styles.sectionTitle}`}>Error</span>
              <span className={`${styles.error}`}>{checkoutError}</span>
            </>
          )}
          <span className={`${styles.sectionTitle} ${styles.paymentBottomSpacer}`}>
            Payment Options
          </span>
          {renderPaymentMethods()}
        </form>
        <p className="paymentInfo">
          Payments are charged to your method of payment within 24 hours prior to end of each
          subscription period. Subscriptions auto-renew. Cancel anytime.
        </p>
      </div>
      <Footer />
    </>
  )
}

export default CheckoutPage
