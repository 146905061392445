import React, { useEffect } from 'react'

import Footer from '../../components/Footer/Footer'
import Loading from '../../components/Loading/Loading'
import RetryLoading from '../../components/RetryLoading/RetryLoading'
import useCheckout from '../../contexts/checkoutContext'
import useData from '../../contexts/dataContext'
import styles from './alreadySubscribed.module.scss'
import { igID } from '../../utils/config'

const AlreadySubscribedPage = () => {
  const { actions, state } = useData()

  const username = useCheckout().state.username

  const loadData = () => {
    actions.loadLanding(igID)
  }

  useEffect(loadData, [])

  if (state.landingError != undefined) {
    return <RetryLoading message={state.landingError} onRetry={loadData} />
  }

  if (state.landing == undefined) {
    return <Loading />
  }

  return (
    <>
      <div className={styles.container}>
        <p className={styles.text}>Welcome back @{username}!</p>
        <p className={`${styles.text} u-margin-bottom-medium`}>
          You already have an active subscription to {state.landing.fullName}.
        </p>
        <p className={styles.text}>
          Email support+{igID}@artistpass.us if you would like to upgrade or cancel your membership.
          We'll do our best to respond timely.
        </p>
      </div>
      <Footer />
    </>
  )
}

export default AlreadySubscribedPage
