import React from 'react'

import styles from './Separator.module.scss'

interface Props {
  text: string
}

const Separator = ({ text }: Props) => {
  return (
    <div className={styles.container}>
      <hr className={styles.line} />
      <span className={styles.text}>{text}</span>
      <hr className={styles.line} />
    </div>
  )
}

export default Separator
