import React, { useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentIntent, PaymentRequest as StripePaymentRequest } from '@stripe/stripe-js'

import Product from '../models/Product'
import { createSubscription } from '../services/scottie-service'
import config from '../utils/config'
import useData from './dataContext'

type State = {
  username: string
  email: string
  checkoutError?: string
  paymentRequest?: StripePaymentRequest
  isProcessingCheckout: boolean
  isLoadingPaymentMethods: boolean
}

interface Actions {
  checkoutWithCard(product: Product, igId: string): Promise<void>
  createPaymentRequest(product: Product, igId: string): Promise<void>
  setUsername(username: string): void
  setEmail(email: string): void
}

const Context = React.createContext<{ state: State; actions: Actions }>(null as any)

const CheckoutProvider = ({ children }: any) => {
  const [checkoutError, setCheckoutError] = useState<string | undefined>(undefined)
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const emailRef = useRef("")
  const usernameRef = useRef("")
  const [isProcessingCheckout, setIsProcessingCheckout] = useState(false)
  const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(true)
  const history = useHistory()
  const stripe = useStripe()
  const elements = useElements()
  const [paymentRequest, setPaymentRequest] = useState<StripePaymentRequest | undefined>(undefined)

  const handleCheckoutError = (igID: string, error: any) => {
    console.log(error)
    if (error.exception === "You are already subscribed") {
      history.push(`/alreadySubscribed`)
      return
    }
    setCheckoutError(error.exception || error.message || config.unexpectedErrorMessage)
  }

  const checkoutWithCard = async (product: Product, igId: string) => {
    setIsProcessingCheckout(true)
    try {
      if (stripe == undefined) {
        setCheckoutError(config.unexpectedErrorMessage)
        return
      }

      const element = elements?.getElement(CardElement)

      if (element == undefined) {
        setCheckoutError(config.unexpectedErrorMessage)
        return
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: element,
      })

      if (paymentMethod == undefined) {
        setCheckoutError(error?.message || config.unexpectedErrorMessage)
        return
      }

      await handleSubscription(paymentMethod.id, product, email, username)
      history.replace(`/checkoutSuccess`, { selectedProduct: product })
    } catch (error) {
      handleCheckoutError(igId, error)
    } finally {
      setIsProcessingCheckout(false)
    }
  }

  const createPaymentRequest = async (product: Product, igId: string) => {
    if (stripe == undefined || product == undefined || paymentRequest != undefined) {
      return
    }

    const newPaymentRequest = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: product.title,
        amount: product.priceInCents,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    })

    const result = await newPaymentRequest.canMakePayment()
    setIsLoadingPaymentMethods(false)

    if (result == undefined) {
      return
    }

    newPaymentRequest.on("paymentmethod", async (event) => {
      try {
        await handleSubscription(
          event.paymentMethod.id,
          product,
          emailRef.current,
          usernameRef.current
        )
        event.complete("success")
        history.replace(`/checkoutSuccess`, { selectedProduct: product })
      } catch (error) {
        event.complete("fail")
        handleCheckoutError(igId, error)
      }
    })

    // Check the availability of the Payment Request API.
    setPaymentRequest(newPaymentRequest)
  }

  const handleSubscription = async (
    paymentMethodId: string,
    product: Product,
    email: string,
    username: string
  ) => {
    const subscription: any = await createSubscription(paymentMethodId, product.id, email, username)

    const invoice = subscription.latest_invoice
    const intent: PaymentIntent = invoice.payment_intent

    if (invoice == undefined || intent == undefined || intent.client_secret == undefined) {
      throw new Error("Empty invoice response.")
    }

    if (intent.status === "requires_action") {
      const result = await stripe?.confirmCardPayment(intent.client_secret, {
        payment_method: paymentMethodId,
      })

      if (result?.error != undefined) {
        throw new Error(result?.error.message || config.unexpectedErrorMessage)
      }

      if (result?.paymentIntent == undefined) {
        throw new Error("Empty invoice response.")
      }

      if (result.paymentIntent.status !== "succeeded") {
        throw new Error("Payment failed.")
      }
      return
    }

    if (intent.status === "requires_payment_method") {
      throw new Error("Your card was declined.")
    }
  }

  /* The .on("paymentmethod") callback on paymentRequest cannot be updated and can not be removed.
  It won't get access to the latest state so we have to use refs to pass it the value*/

  const updateUsername = (newValue: string) => {
    newValue = newValue.substr(0, 1) === '@' ? newValue.substr(1) : newValue
    setUsername(newValue)
    usernameRef.current = newValue
  }

  const updateEmail = (newValue: string) => {
    setEmail(newValue)
    emailRef.current = newValue
  }

  const value = {
    actions: {
      checkoutWithCard,
      createPaymentRequest,
      setUsername: updateUsername,
      setEmail: updateEmail,
    },
    state: {
      username,
      email,
      checkoutError,
      paymentRequest,
      isProcessingCheckout,
      isLoadingPaymentMethods,
    },
  }

  return <Context.Provider value={value}> {children} </Context.Provider>
}

export { CheckoutProvider }

const useCheckout = () => useContext(Context)

export default useCheckout
