export enum ColorTheme {
  light = "light",
  dark = "dark",
  system = "system", // respects system theme if this is specified
}

export default interface LandingConfiguration {
  fullName: string
  avatarURL: string
  verified: boolean
  description: string
  beneficiaryName?: string
  beneficiaryURL?: string
  colorTheme: ColorTheme
}
