import './sass/main.scss'

import React from 'react'
import Div100vh from 'react-div-100vh'
import { Route, Switch } from 'react-router-dom'

import { igID } from './utils/config'

import AlreadySubscribedPage from './pages/alreadySubscribedPage/alreadySubscribedPage'
import CheckoutPage from './pages/checkoutPage/checkoutPage'
import CheckoutSuccessPage from './pages/checkoutSuccessPage/checkoutSuccessPage'
import HomePage from './pages/homePage/homePage'
import LandingPage from './pages/landingPage/landingPage'

const App = () => {
  // Temporarily expose "www" app
  if (!igID) {
    return (
      <Div100vh className="centerContainer" style={{ minHeight: "100rvh" }}>
        <div className="container">
          <Switch>
            <Route path="/" component={HomePage} />
          </Switch>
        </div>
      </Div100vh>
    )
  }

  // "paywall" app
  return (
    <Div100vh className="centerContainer" style={{ minHeight: "100rvh" }}>
      <div className="container">
        <Switch>
          <Route path="/alreadySubscribed" component={AlreadySubscribedPage} />
          <Route path="/checkout" component={CheckoutPage} />
          <Route path="/checkoutSuccess" component={CheckoutSuccessPage} />
          <Route path="/" component={LandingPage} />
        </Switch>
      </div>
    </Div100vh>
  )
}

export default App
